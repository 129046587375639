import React from "react"

import { Link } from "gatsby"
import { Row } from "reactstrap"
import { Col } from "reactstrap"

export default () => (
  <Row className="mt-3 mb-3">
    <Col className="text-center">
      <Link to="/" className="nav-link" activeClassName="active">
        Startseite
      </Link>
    </Col>
    <Col className="text-center">
      <Link to="/ueberuns" className="nav-link" activeClassName="active">
        Über uns
      </Link>
    </Col>
    <Col className="text-center">
      <Link
        to="/dienstleistungen"
        className="nav-link"
        activeClassName="active"
      >
        Dienstleistungen
      </Link>
    </Col>
    <Col className="text-center">
      <Link to="/kontakt" className="nav-link" activeClassName="active">
        Kontakt
      </Link>
    </Col>
    <Col className="text-center">
      <Link to="/impressum" className="nav-link" activeClassName="active">
        Impressum
      </Link>
    </Col>
  </Row>
)
